import ChooseLocationDropdown from './ChooseLocationDropdown';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { selectOpenLocations } from '../../../../state/selectors/locationsSelector';

function mapStateToProps(state: AppState, ...ownProps: any) {
  return {
    selectedAddressOption: state.addressForm.selectedAddressOption,
    isLoading: state.session.isLoadingLocationsSettingsFromServer,
    compId: state.platformParams.compId,
    locations: selectOpenLocations(state),
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ChooseLocationDropdown);
