import React from 'react';
import moment from 'moment-timezone';
import { isAvailableOn } from 'availability';
import Dropdown from '../Dropdown';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import { TimingOption } from '../DispatchSettings/DispatchSettings';
import styles from './DispatchTimeSelector.scss';
import { TranslationFunction } from 'i18next';
import { BusinessNotifications, DispatchInfo, Restaurant, VirtualDispatchType, Address } from '@wix/restaurants-client-logic';
import {
  getAsapText,
  getDishPrepareTime,
  getDispatchTimeSelectorDetails,
  getTimeDropdownOptions,
} from './DispatchTimeSelector.helper';
import { BusinessNotificationDetails } from '../../../../state/selectors/businessNotificationSelector';
import { getDispatchTypeFromVirtual } from '../../../../core/logic/dispatchLogic';
import { PartialLocation } from '../../../../core/oloApi';

interface DispatchTimeProps {
  restaurant: Restaurant | PartialLocation;
  dispatchType: VirtualDispatchType;
  timingOption?: TimingOption;
  dispatchTime?: number;
  onChange: (changes: { timingOption: TimingOption; selectedDateTime?: number }) => void;
  t: TranslationFunction;
  isMobile?: boolean;
  error?: string;
  idealDeliveryArea?: DispatchInfo;
  businessNotification?: BusinessNotificationDetails;
  isRTL?: boolean;
  isModal?: boolean;
  isMl?: boolean;
  address?: Address;
}

export const DispatchTime = ({
  timingOption = 'asap',
  dispatchTime,
  restaurant,
  dispatchType,
  onChange,
  t,
  isMobile,
  error,
  idealDeliveryArea,
  businessNotification,
  isRTL,
  isModal,
  isMl,
  address
}: DispatchTimeProps) => {
  const { timezone, deliveryInfos } = restaurant;
  const asapText = getAsapText({
    timezone,
    deliveryInfos,
    dispatchType,
    locale: restaurant.locale,
    t,
    idealDeliveryArea,
  });

  const { dateError, timeError, dateOptions, timeOptions, finalAvailability, selectedDate } =
    getDispatchTimeSelectorDetails({
      timingOption,
      dispatchTime,
      restaurant,
      dispatchType,
      t,
      error,
      idealDeliveryArea,
      address,
      isMultiLocation: !!isMl,
    });

  const isDeliveryType = dispatchType === 'delivery';
  if (dateOptions.length === 0 && isModal) {
    return null;
  }

  const earliestTimeOptions = dateOptions[0]?.id
    ? getTimeDropdownOptions(
        finalAvailability,
        timezone,
        parseInt(dateOptions[0].id, 10),
        restaurant.orders.future.delayMins,
        getDishPrepareTime(restaurant, getDispatchTypeFromVirtual(dispatchType)),
      )
    : [];

  const isAvailableNow = isAvailableOn(finalAvailability, moment().tz(timezone));
  const isOnlyAsap = (!isMl || isAvailableNow) && restaurant.orders.future.disabled && !restaurant.orders.asap.disabled;
  const isOnlyFutureOrders =
    isDeliveryType && !isMl
      ? businessNotification?.notification === BusinessNotifications.OnlyFutureOrders
      : !restaurant.orders.future.disabled &&
        earliestTimeOptions[0]?.id && !isAvailableNow;

  if (isOnlyFutureOrders) {
    timingOption = 'later';
  }

  const shouldChooseDateAndTime =
    !restaurant.orders.future.disabled &&
    (timingOption === 'later' || restaurant.orders.asap.disabled || isOnlyFutureOrders);

  const timingOptionError = timingOption === 'asap' ? error : undefined;

  return (
    <div data-hook={dataHooks.dispatchTimeSelector} className={styles.wrapper}>
      <Text typography="p2-s" className={styles.label}>
        {isDeliveryType ? t('checkout_main_delivery_time') : t('checkout_main_pickup_time')}
      </Text>
      {isOnlyAsap && (
        <Text typography="p2-m" className={styles.label} data-hook={dataHooks.asapTextWithoutDropdown}>
          {asapText}
        </Text>
      )}
      {!isOnlyFutureOrders && !restaurant.orders.future.disabled && !restaurant.orders.asap.disabled && (
        <Dropdown
          key={asapText}
          className={styles.timing}
          upgrade
          data-hook={dataHooks.dispatchSummaryTimingOption}
          options={[
            { id: 'asap', value: asapText, isSelectable: true },
            { id: 'later', value: t('checkout_main_specific_time'), isSelectable: true },
          ]}
          onChange={({ id }) => {
            const newTimingOption: TimingOption = id as TimingOption;
            onChange({
              timingOption: newTimingOption,
              selectedDateTime: newTimingOption === 'later' ? dispatchTime || Number(timeOptions[0].id) : undefined,
            });
          }}
          initialSelectedId={timingOption}
          error={Boolean(timingOptionError)}
          errorMessage={timingOptionError}
          mobileNativeSelect
        />
      )}
      {shouldChooseDateAndTime && (
        <React.Fragment>
          <div className={`${styles.dateTimeWrapper} ${isMobile && styles.mobile}`}>
            <div id="date-selector" className={styles.date}>
              <Dropdown
                upgrade
                data-hook={dataHooks.dispatchSummaryTimingDate}
                options={dateOptions}
                onChange={(selectedOption) => {
                  const selectedTime = timeOptions.find((to) => to.id === String(dispatchTime));
                  const newTimeOptions = getTimeDropdownOptions(
                    finalAvailability,
                    restaurant.timezone,
                    Number(selectedOption.id),
                    restaurant.orders.future.delayMins,
                    getDishPrepareTime(restaurant, getDispatchTypeFromVirtual(dispatchType)),
                  );
                  const newSelectedDateTime =
                    (selectedTime && Number(newTimeOptions.find((to) => to.value === selectedTime.value)?.id)) ||
                    Number(newTimeOptions[0].id);

                  onChange({
                    timingOption,
                    selectedDateTime: newSelectedDateTime || Number(selectedOption.id),
                  });
                }}
                initialSelectedId={String(selectedDate)}
                error={Boolean(dateError)}
                errorMessage={dateError}
                mobileNativeSelect
              />
            </div>
            <div className={`${styles.spacer} ${isMobile && styles.mobile}`} />
            <div id="time-selector" className={styles.time}>
              <Dropdown
                upgrade
                data-hook={dataHooks.dispatchSummaryTimingTime}
                options={timeOptions}
                onChange={(selectedOption) => {
                  onChange({ timingOption, selectedDateTime: Number(selectedOption.id) });
                }}
                initialSelectedId={dispatchTime ? String(dispatchTime) : timeOptions[0].id}
                error={Boolean(timeError)}
                errorMessage={timeError}
                disabled={Boolean(!timeError && dateError)}
                errorTooltipPlacement={isRTL ? 'top-start' : 'top-end'}
                mobileNativeSelect
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

DispatchTime.displayName = 'DispatchTime';

export default React.memo(DispatchTime);
