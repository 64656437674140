import React from 'react';
import { withRouter, matchPath, RouteComponentProps, Route, Switch, Redirect } from 'react-router-dom';
import { Location } from 'history';
import { ItemsHash } from '@wix/restaurants-client-logic';
import { CloseModalPayload, OpenModalPayload } from '../../../../state/session/session.actions.types';
import {
  CreateNewPendingOrderItemPayload,
  OpenExistingOrderItemPayload,
} from '../../../../state/cart/cart.actions.types';
import { Modals, RouteUrls } from '../../../../core/constants';
import CheckoutFlow from '../CheckoutFlow';
import MenuView from '../MenuView';
import ThankYou from '../ThankYou';
import RouteWrapper from '../RouteWrapper';

const modalUrls = [RouteUrls.DISPATCH_SETTINGS_MODAL, RouteUrls.DISH_MODAL, RouteUrls.DISH_MODAL_EDIT];
const isModalPath = (path: string) => !!modalUrls.find((url) => matchPath(path, { path: url }));

function simpleMemoize(f: Function) {
  const cache: Record<string, any> = {};

  function memoized(arg: string = '') {
    if (cache[arg]) {
      return cache[arg];
    } else {
      const result: any = f(arg);
      cache[arg] = result;
      return result;
    }
  }

  memoized.clearCache = (k: string = '') => (cache[k] = null);

  return memoized;
}

export interface RoutesProps {
  routeUrlOverride?: string;
  itemsHash: ItemsHash;
  orderItemsCount: number;
  isMultiLocation?: boolean;
  openModal: (payload: OpenModalPayload) => void;
  closeModal: (payload: CloseModalPayload) => void;
  createNewPendingOrderItem: (payload: CreateNewPendingOrderItemPayload) => void;
  clearOrderResponse: (payload: void) => void;
  openExistingOrderItem: (payload: OpenExistingOrderItemPayload) => void;
}

class Routes extends React.Component<RoutesProps & RouteComponentProps> {
  nonModalLocation?: Location;

  componentDidMount() {
    if (!isModalPath(this.props.location.pathname)) {
      this.nonModalLocation = this.props.location;
    }

    // For testing
    if (this.props.routeUrlOverride) {
      this.props.history.push(this.props.routeUrlOverride);
    }
  }

  shouldComponentUpdate(nextProps: RoutesProps & RouteComponentProps) {
    if (
      nextProps.routeUrlOverride &&
      nextProps.routeUrlOverride !== this.props.routeUrlOverride &&
      nextProps.routeUrlOverride !== this.props.location.pathname
    ) {
      this.props.history.push(nextProps.routeUrlOverride);
      return false;
    }

    return this.props.location.pathname !== nextProps.location.pathname;
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    const { location } = this.props;

    if (!isModalPath(location.pathname)) {
      this.nonModalLocation = location;
    }
  }

  renderDishModal = simpleMemoize((itemId: string) => {
    const { itemsHash, openModal, closeModal, createNewPendingOrderItem } = this.props;
    const redirectPath = (this.nonModalLocation?.pathname as RouteUrls) || '/';

    if (!itemsHash[itemId]) {
      return <Redirect to={redirectPath} />;
    }

    return (
      <RouteWrapper
        onEnter={() => {
          createNewPendingOrderItem({ itemId });
          openModal({
            modal: Modals.DISH_MODAL,
            redirectOnClose: redirectPath,
          });
        }}
        onExit={() => {
          closeModal({ modal: Modals.DISH_MODAL });
          this.renderDishModal.clearCache(itemId);
        }}
      />
    );
  });

  renderDishModalEdit = simpleMemoize((itemIndex: string) => {
    const { orderItemsCount, openModal, closeModal, openExistingOrderItem } = this.props;
    const redirectPath = (this.nonModalLocation?.pathname as RouteUrls) || '/';

    if (isNaN(Number(itemIndex)) || Number(itemIndex) >= orderItemsCount) {
      return <Redirect to={redirectPath} />;
    }

    return (
      <RouteWrapper
        onEnter={() => {
          if (!isNaN(Number(itemIndex)) && Number(itemIndex) < orderItemsCount) {
            openExistingOrderItem({ itemIndex: Number(itemIndex) });
            openModal({
              modal: Modals.DISH_MODAL,
              redirectOnClose: redirectPath,
            });
          }
        }}
        onExit={() => {
          closeModal({ modal: Modals.DISH_MODAL });
          this.renderDishModalEdit.clearCache(itemIndex);
        }}
      />
    );
  });

  renderThankYouPage = simpleMemoize(() => {
    const { clearOrderResponse } = this.props;

    return (
      <RouteWrapper onExit={() => clearOrderResponse()}>
        <ThankYou />
      </RouteWrapper>
    );
  });

  renderDispatchSettingsModal = simpleMemoize(() => {
    const { openModal, closeModal, isMultiLocation, location } = this.props;
    let modal: OpenModalPayload;
    if (isMultiLocation) {
      modal = {
        modal: Modals.ADDRESS_INFORMATION_MODAL,
        redirectOnClose: '/',
        context: { origin: (location.state as any)?.origin },
      };
    } else {
      modal = { modal: Modals.DISPATCH_SETTINGS_MODAL, redirectOnClose: '/' };
    }

    return <RouteWrapper onEnter={() => openModal(modal)} onExit={() => closeModal({ modal: modal.modal })} />;
  });

  render() {
    const { location } = this.props;
    const isModal = isModalPath(location.pathname);
    const isExact = !isModal || !!this.nonModalLocation;

    return (
      <>
        <Switch location={isModal ? this.nonModalLocation : location}>
          <Route exact={isExact} path={RouteUrls.THANK_YOU} render={() => this.renderThankYouPage()} />
          <Route exact={isExact} path={RouteUrls.CHECKOUT_FLOW} component={CheckoutFlow} />
          <Route exact={isExact} path={RouteUrls.INITIAL_VIEW} component={MenuView} />
          {!isModal && <Redirect to="/" />}
        </Switch>
        <Route exact path={RouteUrls.DISH_MODAL} render={({ match: { params } }) => this.renderDishModal(params.id)} />
        <Route
          exact
          path={RouteUrls.DISH_MODAL_EDIT}
          render={({ match: { params } }) => this.renderDishModalEdit(params.cartIndex)}
        />
        <Route exact path={RouteUrls.DISPATCH_SETTINGS_MODAL} render={() => this.renderDispatchSettingsModal()} />
      </>
    );
  }
}

export default withRouter(Routes);
