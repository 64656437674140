import React from 'react';
import NavigationHeader from '../NavigationHeader';
import StickyV2 from '../Sticky/StickyV2';
import styles from './StickyNavigationHeader.scss';

export interface StickyNavigationHeaderProps {}

const StickyNavigationHeader: React.FC<StickyNavigationHeaderProps> = () => {
  const [showButtonPlaceholder, toggleShowButtonPlaceholder] = React.useState(false);
  const StickyComponent = StickyV2;

  return (
    <StickyComponent className={styles.wrapper} onChange={(isSticky) => toggleShowButtonPlaceholder(isSticky)}>
      <NavigationHeader showButtonPlaceholder={showButtonPlaceholder} />
    </StickyComponent>
  );
};

StickyNavigationHeader.displayName = 'StickyNavigationHeader';

export default StickyNavigationHeader;
