import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import React from 'react';
import dataHooks from '../../data-hooks';
import { BusinessNotifications } from '@wix/restaurants-client-logic';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/error.svg';
import styles from './NotificationBar.scss';
import { translate } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import { LocationsWithNotification } from '../../../../state/selectors/locationsSelector';
import { useAppSelector } from '../../../../core/hooks/redux';

function getNotificationBarText(t: TranslationFunction, notification?: BusinessNotifications, comment?: string) {
  switch (notification) {
    case BusinessNotifications.OlOunavailable:
      return comment || t('checkout_main_onlineordering_closed_errortext');
    case BusinessNotifications.OnlyFutureOrders:
      return t('cart_issues_error_banner_restaurant_closed_future');
    case BusinessNotifications.RestaurantClosed:
      return t('cart_issues_error_banner_restaurant_closed');
    case BusinessNotifications.NotPremium:
    case BusinessNotifications.NoPaymentMethods:
    case BusinessNotifications.NoPhysicalLocation:
      return t('cart_issues_error_banner_no_olo');
    default:
      return '';
  }
}

function getNotificationBarTextMultiLocation(
  t: TranslationFunction,
  locations: LocationsWithNotification,
  comment?: string,
) {
  if (locations.length === 0) {
    return '';
  }

  if (locations.every(({ notification }) => notification === BusinessNotifications.RestaurantClosed)) {
    return t('cart_issues_error_banner_restaurant_closed');
  } else if (locations.every(({ notification }) => notification === BusinessNotifications.OlOunavailable)) {
    return comment || t('checkout_main_onlineordering_closed_errortext');
  } else if (
    locations.every(
      ({ notification }) =>
        notification === BusinessNotifications.OlOunavailable ||
        notification === BusinessNotifications.RestaurantClosed,
    )
  ) {
    return t('cart_issues_error_banner_restaurant_closed');
  } else if (locations.every(({ notification }) => notification === BusinessNotifications.OnlyFutureOrders)) {
    return t('cart_issues_error_banner_restaurant_closed_future');
  } else if (
    locations.some(
      ({ notification }) =>
        notification === BusinessNotifications.NoPaymentMethods ||
        notification === BusinessNotifications.NoPhysicalLocation ||
        notification === BusinessNotifications.NotPremium,
    )
  ) {
    return t('cart_issues_error_banner_no_olo');
  } else {
    return '';
  }
}

export interface NotificationBarProps {
  notification?: BusinessNotifications;
  comment?: string;
  className?: string;
  hasErrorState?: boolean;
  shouldShowNotification?: (n?: BusinessNotifications) => boolean;
  locations: LocationsWithNotification;
  t: TranslationFunction;
}

const NotificationBar: React.FC<NotificationBarProps> = ({
  notification,
  comment,
  className,
  hasErrorState = false,
  locations,
  shouldShowNotification = (n?: BusinessNotifications) => true,
  t,
}) => {
  const isMultiLocation = useAppSelector((state) => state.session.isMultiLocation);
  const text = shouldShowNotification(notification)
    ? isMultiLocation
      ? getNotificationBarTextMultiLocation(t, locations, comment)
      : getNotificationBarText(t, notification, comment)
    : '';
  const notificationType =
    hasErrorState &&
    (notification === BusinessNotifications.NotPremium ||
      notification === BusinessNotifications.NoPaymentMethods ||
      notification === BusinessNotifications.NoPhysicalLocation)
      ? 'error'
      : 'default';
  return text ? (
    <SectionNotification
      type={notificationType}
      data-hook={dataHooks.notificationBar}
      className={`${className} ${styles.wrapper}`}
    >
      {notificationType === 'error' && <SectionNotification.Icon data-hook="error-icon" icon={<ErrorIcon />} />}
      <SectionNotification.Text>{text}</SectionNotification.Text>
    </SectionNotification>
  ) : null;
};

NotificationBar.displayName = 'NotificationBar';

export default translate()(NotificationBar);
