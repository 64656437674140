import React from 'react';
import { TranslationFunction } from 'i18next';
import { translate, useBi } from 'yoshi-flow-editor-runtime';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/SectionNotification';
import Text from '../../core-components/Text';
import { TextButton } from '../TextButton';
import dataHooks from '../../data-hooks';
import styles from './ThankYou.scss';

interface ThankYouLoyaltyBoxProps {
  loginMember: any;
  pointsToBeEarned: number;
  customPointsName: string;
  isUserLoggedIn?: boolean;
  loyaltyProgramName: string;
  loyaltyRewardId: string;
  pointsBalance?: number;
  goToLoyaltyPage: Function;
  t: TranslationFunction;
}

function getLoggedInDescription(
  t: TranslationFunction,
  pointsToBeEarned: number,
  customPointsName: string,
  pointsBalance: number | undefined,
) {
  const stringForCustomPointsName =
    pointsToBeEarned === pointsBalance
      ? 'thank_you_page_loyalty_pointsearned_text_short'
      : 'thank_you_page_loyalty_pointsearned_text';

  const stringForDefaultPointsName =
    pointsToBeEarned === pointsBalance
      ? 'thank_you_page_loyalty_pointsearned_default_name_text_short'
      : 'thank_you_page_loyalty_pointsearned_default_name_text';

  const translationKey = customPointsName ? stringForCustomPointsName : stringForDefaultPointsName;
  return t(translationKey, {
    pointearned: pointsToBeEarned,
    pointnameplural: customPointsName,
    pointtotal: pointsBalance,
  });
}

const ThankYouLoyaltyBox: React.FC<ThankYouLoyaltyBoxProps> = ({
  loginMember,
  t,
  pointsToBeEarned,
  customPointsName,
  isUserLoggedIn,
  loyaltyProgramName,
  pointsBalance = 0,
  goToLoyaltyPage,
  loyaltyRewardId,
}) => {
  const title = isUserLoggedIn ? loyaltyProgramName : t('thank_you_page_loyalty_login_title');

  const description = isUserLoggedIn
    ? getLoggedInDescription(t, pointsToBeEarned, customPointsName, pointsBalance)
    : t('thank_you_page_loyalty_login_text', { earnednumber: pointsToBeEarned, pointname: customPointsName });

  const cta = isUserLoggedIn ? t('thank_you_page_loyalty_viewrewards_cta') : t('thank_you_page_loyalty_login_cta');

  const biLogger = useBi();

  const onCtaClick = React.useCallback(() => {
    if (isUserLoggedIn) {
      biLogger.loyaltyViewMyRewards({ rewardId: loyaltyRewardId });
      goToLoyaltyPage();
    } else {
      loginMember();
    }
  }, [loginMember, goToLoyaltyPage, isUserLoggedIn, biLogger, loyaltyRewardId]);

  return (
    <div className={styles.loyaltyWrapper}>
      <div className={styles.restaurantDetailsWrapper}>
        <div className={styles.restaurantDetails} data-hook={dataHooks.thankYouRestaurantAddress}>
          <Text typography="p2-m" data-hook={dataHooks.thankYouLoyaltyBoxTitle}>
            {title}
          </Text>
          <Text typography="p2-s" className={styles.takeoutLabel} data-hook={dataHooks.thankYouLoyaltyBoxDescription}>
            {description}
          </Text>
        </div>
      </div>
      <TextButton
        priority={TEXT_BUTTON_PRIORITY.primary}
        onClick={onCtaClick}
        data-hook={dataHooks.thankYouLoyaltyBoxCta}
      >
        <Text typography="p2-s-colorless">{cta}</Text>
      </TextButton>
    </div>
  );
};

ThankYouLoyaltyBox.displayName = 'ThankYouLoyaltyBox';

export default translate()(ThankYouLoyaltyBox);
