import React from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import Text from '../../core-components/Text';
import styles from './ModalLayoutSelection.scss';
import dataHooks from '../../data-hooks';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import classNames from 'classnames';

export interface ModalLayoutSelectionProps {
  children?: React.ReactNode;
  header: string | React.ReactNode;
  footer?: React.ReactNode;
  onCloseClick: () => void;
  'data-hook'?: string;
  className?: string;
  scrollable?: boolean;
}

function unlockScroll() {
  return new Promise((resolve) => {
    clearAllBodyScrollLocks();
    setTimeout(resolve, 16);
  });
}

const ModalLayoutSelection: React.FC<ModalLayoutSelectionProps> = ({
  header,
  children,
  footer,
  onCloseClick,
  className,
  scrollable,
  ...rest
}) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    unlockScroll().then(() => {
      if (scrollContainerRef.current) {
        disableBodyScroll(scrollContainerRef.current, {
          // allow scroll on dropdowns
          allowTouchMove: (el) => el.className.indexOf('options') > -1 || el.className.indexOf('dropdown') > -1,
        });
      }
    });

    return () => {
      unlockScroll();
    };
  }, []);

  return (
    <div className={classNames([styles.wrapper, className])} data-hook={rest['data-hook']}>
      <button
        data-hook={dataHooks.modalCloseButton}
        onClick={() => onCloseClick()}
        className={styles.closeIcon}
        aria-label="Close"
      >
        <Close />
      </button>
      <Text typography="header-xs" tagName="h2" className={styles.header}>
        {header}
      </Text>
      <div className={classNames([styles.content, scrollable && styles.scrollable])} ref={scrollContainerRef}>
        {children}
      </div>
      <div className={styles.footer}>{footer}</div>
    </div>
  );
};

ModalLayoutSelection.displayName = 'ModalLayoutSelection';

export default ModalLayoutSelection;
