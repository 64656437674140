import React from 'react';
import _ from 'lodash';
import styles from './ErrorBanner.scss';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { ReactComponent as Error } from 'wix-ui-tpa/dist/statics/assets/icons/Error.svg';
import { PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import { Trans } from 'yoshi-flow-editor-runtime';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

export interface ErrorBannerProps {
  key?: string;
  value: string;
  'data-hook'?: string;
}

const ErrorBanner: React.FC<ErrorBannerProps> = (props) => {
  return (
    <SectionNotification data-hook={props['data-hook']} type="error" className={styles.wrapper}>
      <SectionNotification.Icon icon={<Error />} />
      <SectionNotification.Text>{props.value}</SectionNotification.Text>
    </SectionNotification>
  );
};

ErrorBanner.displayName = 'ErrorBanner';

export default ErrorBanner;
