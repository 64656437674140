import AddressInformationView from './AddressInformationView';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getFormattedAddressWithComment } from '@wix/restaurants-client-logic';
import { AppState } from '../../../../state/createStore';
import { EMPTY_ADDRESS } from '../../../../core/constants';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import {
  setCurbside,
  setCurbsideOutfitInfo,
  setDeliveryAddressFromForm,
} from '../../../../state/checkout/checkout.actions';
import {
  toggleAllErrors,
  setDeliveryAddressField,
  setFieldError,
} from '../../../../state/addressInformationForm/addressForm.actions';
import { deliveryAreaSelectorByAddressForm } from '../../../../state/selectors/deliveryAreaSelector';
import { openModal, saveAddressToServer, saveStateToSessionStorage } from '../../../../state/session/session.actions';
import { PickupDispatch } from '@wix/restaurants-client-logic/dist/types/types/Dispatch';
import { getDineInInfo, getVirtualDispatchTypeFromDispatch } from '../../../../core/logic/dispatchLogic';
import { selectOpenLocations } from '../../../../state/selectors/locationsSelector';

function mapStateToProps(state: AppState, ownProps: any) {
  const { displayableOrderItems } = cartSummarySelector(state);
  const { restaurant } = state.session;
  const idealDeliveryArea = deliveryAreaSelectorByAddressForm(state);

  return {
    ...ownProps,
    dispatchType: getVirtualDispatchTypeFromDispatch(state.checkout.dispatch),
    address: state.checkout.dispatch.type === 'delivery' ? state.checkout.dispatch.address : EMPTY_ADDRESS,
    restaurant,
    isCurbside: state.checkout.dispatch.type === 'takeout' && state.checkout.dispatch.isCurbside,
    curbsideAdditionalInformation:
      state.checkout.dispatch.type === 'takeout' ? state.checkout.dispatch.curbsideAdditionalInformation : undefined,
    dispatchTime: state.checkout.dispatch.time,
    fieldsErrors: state.addressForm.fieldErrors,
    formattedAddressWithComment: getFormattedAddressWithComment(restaurant.address),
    selectedAddressOption: state.addressForm.selectedAddressOption,
    idealDeliveryArea: idealDeliveryArea?.dispatchInfo,
    errorOrderItem: displayableOrderItems.find((orderItem) => orderItem.errors.length || orderItem.isDishDeleted),
    timezone: state.session.restaurant.timezone,
    errorsVisibility: state.addressForm.errorsVisibility,
    deliveryInfos: restaurant.deliveryInfos,
    locale: state.session.restaurant.locale,
    initialTableNumber: (state.checkout.dispatch as PickupDispatch).contactlessDineIn?.labelValue,
    locations: selectOpenLocations(state),
    dineInInfo: getDineInInfo(state.session.restaurant),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      toggleAllErrors,
      setFieldError,
      setDeliveryAddressFromForm,
      openModal,
      saveAddressToServer,
      setDeliveryAddressField,
      saveStateToSessionStorage,
      setCurbside,
      setCurbsideOutfitInfo,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressInformationView);
