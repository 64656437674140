import React from 'react';
import { translate, useBi } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import { Tooltip } from 'wix-ui-tpa/Tooltip';
import moment from 'moment';
import 'moment/min/locales.min';
import dataHooks from '../../data-hooks';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import styles from './DispatchSummary.scss';
import { RouteUrls } from '../../../../core/constants';
import Text from '../../core-components/Text';
import { Address, DispatchInfo, VirtualDispatchType } from '@wix/restaurants-client-logic';
import { useHistory } from 'react-router-dom';
import { getAsapText } from '../DispatchTimeSelector/DispatchTimeSelector.helper';
import { TooltipSkin } from 'wix-ui-tpa/dist/src/components/Tooltip/TooltipEnums';
import { useAppSelector } from '../../../../core/hooks/redux';

export interface DispatchSummaryProps {
  dispatchType: VirtualDispatchType;
  isCurbside?: boolean;
  dispatchTime?: number;
  timezone: string;
  address?: Address;
  deliveryInfos: DispatchInfo[];
  t: TranslationFunction;
  idealDeliveryArea?: DispatchInfo;
  disabled?: boolean;
  locale: string;
  tableLabel?: string;
  tableNumber?: string;
  isLocationPicked: boolean;
}

function getShortAddress(address: Address) {
  const { number, street, city } = address;
  if (number && street && city) {
    return `${number} ${street}, ${city}`;
  } else if (number && street) {
    return `${number} ${street}`;
  } else if (street && city) {
    return `${street}, ${city}`;
  }
  return address.formatted;
}

function getText(
  t: TranslationFunction,
  dispatchType: VirtualDispatchType,
  timezone: string,
  locale: string,
  deliveryInfos: DispatchInfo[],
  dispatchTime?: number,
  idealDeliveryArea?: DispatchInfo,
  isCurbside?: boolean,
  tableLabel?: string,
  tableNumber?: string,
  address?: Address,
) {
  const timing = dispatchTime
    ? moment(dispatchTime).tz(timezone).locale(locale).format('MMM D, h:mm A')
    : getAsapText({ deliveryInfos, timezone, locale, dispatchType, t, idealDeliveryArea });

  switch (dispatchType) {
    case 'delivery':
      return address?.formatted
        ? `${t('order_settings_modal_deliver_label')} ${getShortAddress(address)}, ${timing}`
        : `${t('checkout_button_delivery')}, ${timing}`;
    case 'dine-in':
      return t('mobile_ordering_deliveryoption_DineIn_Label', { InputLabel: tableLabel, number: tableNumber });
    case 'takeout':
      return isCurbside
        ? `${t('online_ordering_deliveryoption_Curbside_Label')}, ${timing}`
        : `${t('checkout_button_pickup')}, ${timing}`;
    default:
      return `${t('checkout_button_pickup')}, ${timing}`;
  }
}

function getTooltipContent(t: TranslationFunction, dispatchType: VirtualDispatchType) {
  switch (dispatchType) {
    case 'delivery':
      return t('main_page_delivery_address_tooltip');
    case 'takeout':
      return t('main_page_pickup_location_tooltip');
    default:
      return '';
  }
}

const DispatchSummary: React.FC<DispatchSummaryProps> = ({
  dispatchType,
  dispatchTime,
  timezone,
  deliveryInfos,
  locale,
  t,
  idealDeliveryArea,
  disabled,
  isCurbside,
  tableLabel,
  tableNumber,
  isLocationPicked,
  address,
}) => {
  const biLogger = useBi();
  const history = useHistory();

  const isMultiLocation = useAppSelector((state) => state.session.isMultiLocation);

  const handleClick = (e: React.SyntheticEvent) => {
    if (!disabled) {
      e.stopPropagation();
      biLogger.openDispatchSettings({});
      history.push(RouteUrls.DISPATCH_SETTINGS_MODAL, { origin: 'menus' });
    }
  };

  const showEmptyState = isMultiLocation && !isLocationPicked;
  return (
    <div className={styles.layoutWrapper}>
      <div
        onClick={handleClick}
        data-hook={dataHooks.dispatchSummaryWrapper}
        className={`${styles.wrapper} ${disabled && styles.disabled}`}
      >
        {showEmptyState ? (
          <Text typography="p2-m" data-hook={dataHooks.dispatchSummaryText}>
            {t('disptach_summary_empty_state')}
          </Text>
        ) : (
          <React.Fragment>
            <Text typography="p2-m" data-hook={dataHooks.dispatchSummaryText} className={styles.dispatchSummaryText}>
              {getText(
                t,
                dispatchType,
                timezone,
                locale,
                deliveryInfos,
                dispatchTime,
                idealDeliveryArea,
                isCurbside,
                tableLabel,
                tableNumber,
                address,
              )}
            </Text>
            <TextButton
              priority={TEXT_BUTTON_PRIORITY.primary}
              onClick={handleClick}
              data-hook={dataHooks.dispatchSummary}
              className={styles.button}
              disabled={disabled}
            >
              <Text typography="p2-m-colorless">{t('online_ordering_delivery_time_change')}</Text>
            </TextButton>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

DispatchSummary.displayName = 'DispatchSummary';

export default translate()(DispatchSummary);
