import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { closeModal, navigate, openModal } from '../../../../state/session/session.actions';
import { bindActionCreators, Dispatch } from 'redux';
import {
  addOrderCoupon,
  changeOrderItemCount,
  removeOrderCoupon,
  removeOrderItem,
  setOrderComment,
} from '../../../../state/cart/cart.actions';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import { hasCouponsSelector } from '../../../../state/selectors/hasCouponsSelector';
import Cart from './Cart';
import { translate } from 'yoshi-flow-editor-runtime';
import { loyaltyProgramSelector } from '../../../../state/selectors/loyaltyProgramSelector';
import {
  resetLoyaltyPointsToDefaultValue,
  setLoyaltyPointsToRedeem,
  loginMember,
} from '../../../../state/checkout/checkout.actions';
import { getDisplayableRewards } from './Cart.helper';
import { calcEarnedPoints, isLoyaltyDiscount } from '@wix/restaurants-client-logic';
import { getVirtualDispatchTypeFromDispatch } from '../../../../core/logic/dispatchLogic';

function mapStateToProps(state: AppState, ownProps: any) {
  const {
    displayableDiscounts,
    displayablePriceComponents,
    displayableOrderItems,
    totalOrderPrice,
    maxRedeemablePoints,
    redeemPoints,
    priceComponents,
  } = cartSummarySelector(state);

  const {
    pointsRequired,
    currencyValue,
    customPointsName,
    pointsBalance,
    loyaltyProgramName,
    loyaltyRewardId,
    isLoyaltyEarnActive,
    isLoyaltyRedeemActive,
  } = loyaltyProgramSelector(state);
  const { currency, locale } = state.session.restaurant;

  const displayableRewards = getDisplayableRewards({
    isLoyaltyRedeemActive,
    pointsRequired,
    maxRedeemablePoints,
    locale,
    currency,
    currencyValue,
  });

  const pointsToBeEarned = calcEarnedPoints({
    rules: state.session.loyaltyEarningRules || [],
    moneyAmountInCents: priceComponents.subtotal,
  });

  return {
    comment: state.cart.comment,
    displayableDiscounts,
    displayablePriceComponents,
    displayableOrderItems,
    couponCode: (state.cart.coupon?.type === 'success' && state.cart.coupon.code) || undefined,
    couponError: (state.cart.coupon?.type === 'failure' && state.cart.coupon.message) || undefined,
    hasCoupons: hasCouponsSelector(state),
    dispatchType: getVirtualDispatchTypeFromDispatch(state.checkout.dispatch),
    isMobile: state.platformParams.isMobile,
    isRTL: state.platformParams.isRTL,
    selectedAddressOption: state.addressForm.selectedAddressOption,
    totalOrderPrice,
    restaurant: state.session.restaurant,
    displayableRewards,
    pointsBalance,
    redeemPoints,
    loyaltyDiscountIconUrl: state.session.loyaltyProgram?.points?.icon?.url,
    shouldDisplayLoyaltyButton:
      state.session.menu.chargesV2 &&
      Boolean(state.session.menu.chargesV2.find(isLoyaltyDiscount)) &&
      !displayableDiscounts.find((dd) => dd.isLoyalty) &&
      state.session.isUserLoggedIn &&
      pointsBalance >= pointsRequired &&
      isLoyaltyRedeemActive,
    isUserLoggedIn: state.session.isUserLoggedIn,
    customPointsName,
    pointsRequired,
    currencyValue,
    centsAmount: priceComponents.subtotal,
    isLoyaltyEarnActive,
    loyaltyProgramName,
    loyaltyRewardId,
    pointsToBeEarned,
    earningRules: state.session.loyaltyEarningRules || [],
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      navigate,
      closeModal,
      setOrderComment,
      addOrderCoupon,
      removeOrderCoupon,
      removeOrderItem,
      changeOrderItemCount,
      openModal,
      setLoyaltyPointsToRedeem,
      resetLoyaltyPointsToDefaultValue,
      loginMember,
    },
    dispatch,
  );
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(Cart));
