import { connect } from 'react-redux';
import { translate } from 'yoshi-flow-editor-runtime';
import { AppState } from '../../../../state/createStore';
import { DineInDetails } from './DineInDetails';
import { getDineInInfo } from '../../../../core/logic/dispatchLogic';

function mapStateToProps(state: AppState, ownProps: any) {
  const dineInInfo = getDineInInfo(state.session.restaurant);
  return {
    ...ownProps,
    description: dineInInfo ? dineInInfo.instructions : '',
    label: dineInInfo ? dineInInfo.label : '',
    address: state.session.restaurant.address.formatted,
    isDineInUrl: state.session.isDineInUrl,
    isMultiLocation: state.session.isMultiLocation,
    locations: state.session.locations,
  };
}

export default translate()(connect(mapStateToProps)(DineInDetails));
