import React, { useState } from 'react';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import { translate, TranslationFunction } from 'yoshi-flow-editor-runtime';
import TextField from '../TextField';
import styles from './DineInDetails.scss';
import ChooseLocationDropdown from '../ChooseLocationDropdown/index';
import { PartialLocation } from '../../../../core/oloApi';
import { getDineInInfo } from '../../../../core/logic/dispatchLogic';

interface DineInDetailsProps {
  description: string;
  tableNumber: string;
  label: string;
  onChangeTableNumber: (num: string) => void;
  shouldShowError: boolean;
  t: TranslationFunction;
  isDineInUrl: boolean;
  isMultiLocation: boolean;
  address: string;
  onLocationChange?: (id: string) => void;
  locations?: PartialLocation[];
  selectedLocation?: string;
}
export const DineInDetails = translate()((props: DineInDetailsProps) => {
  const {
    t,
    tableNumber,
    shouldShowError,
    onChangeTableNumber,
    address,
    isDineInUrl,
    isMultiLocation,
    onLocationChange,
    locations,
    selectedLocation,
  } = props;

  const [label, setLabel] = useState(props.label);
  const [description, setDescription] = useState(props.description);
  const isDineInLocationPicked = selectedLocation || locations?.length === 1;
  const shouldShowPicker = isMultiLocation && !isDineInUrl;
  const shouldShowDineInInfo = !isMultiLocation || (shouldShowPicker && isDineInLocationPicked) || !shouldShowPicker;

  const getExtraContentForLocation = () => {
    return !isMultiLocation ? undefined : isDineInUrl ? getLocationText() : getLocationPicker();
  };

  const getLocationPicker = () => (
    <ChooseLocationDropdown
      labelText={t('order_settings_modal_choose_location_label')}
      onChange={onSelectLocation}
      filters="dine-in"
      location={selectedLocation}
    />
  );

  const onSelectLocation = (id: string) => {
    const newLocation = locations?.find((location) => location.currentLocationId === id);
    if (newLocation) {
      const dineInInfo = getDineInInfo(newLocation);
      if (dineInInfo) {
        setLabel(dineInInfo.label);
        setDescription(dineInInfo.instructions);
      }
    }
    onLocationChange && onLocationChange(id);
  };

  const getLocationText = () => <Text typography={'p2-m'}>{address}</Text>;
  return (
    <div className={styles.wrapperDinein} data-hook={dataHooks.dispatchSettingDineInDetailsWrapper}>
      {getExtraContentForLocation()}
      {shouldShowDineInInfo && (
        <React.Fragment>
          <Text typography="p2-m" data-hook={dataHooks.dispatchSettingDineInDescription} className={styles.description}>
            {description}
          </Text>
          <Text typography="p2-m" data-hook={dataHooks.dispatchSettingDineInLabel} className={styles.label}>
            {label + '*'}
          </Text>
          <TextField
            data-hook={dataHooks.dispatchSettingDineInTableNumber}
            value={tableNumber || ''}
            onChange={(e) => onChangeTableNumber(e.target.value || '')}
            className={styles.inputField}
            error={shouldShowError}
            errorMessage={
              shouldShowError ? t('delivery_modal_PickupOption_Curbside_FieldLabel_ErrorMessage') : undefined
            }
            maxLength={40}
            showCharCount
          />
        </React.Fragment>
      )}
    </div>
  );
});
