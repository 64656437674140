import PolicyView from '../PolicyView';
import styles from './CheckoutSubmit.scss';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import Button from '../Button';
import { PRIORITY } from 'wix-ui-tpa/Button';
import React from 'react';
import { DispatchType, Policy } from '@wix/restaurants-client-logic';
import { OpenModalPayload } from '../../../../state/session/session.actions.types';
import { SetContactlessDeliveryPayload } from '../../../../state/checkout/checkout.actions.types';
import { TranslationFunction } from 'i18next';
import ToggleSwitch from '../ToggleSwitch';

interface CheckoutSubmitProps {
  orderPolicy?: Policy;
  paymentMethod?: string;
  isContactlessDeliveryChecked?: boolean;
  openModal: (payload: OpenModalPayload) => void;
  submitOrder: Function;
  isLoading?: boolean;
  dispatchType: DispatchType;
  setContactlessDelivery: (payload: SetContactlessDeliveryPayload) => void;
  t: TranslationFunction;
}
export const CheckoutSubmit: React.FC<CheckoutSubmitProps> = ({
  orderPolicy,
  dispatchType,
  paymentMethod,
  isContactlessDeliveryChecked,
  openModal,
  setContactlessDelivery,
  submitOrder,
  isLoading,
  t,
}) => {
  const [showCheckboxError, setShowCheckboxError] = React.useState(false);
  const hasCheckbox = Boolean(orderPolicy?.policyCheckbox);
  const checkedByDefault = Boolean(orderPolicy?.policyCheckbox?.checkedByDefault === 'CHECKED_BY_DEFAULT');
  const [isButtonDisabled, setButtonDisabled] = React.useState(hasCheckbox && !checkedByDefault);
  const handlePolicyChange = React.useCallback(() => {
    setButtonDisabled(!isButtonDisabled);
    setShowCheckboxError(false);
  }, [isButtonDisabled]);
  const shouldShowContactlessOption = dispatchType === 'delivery' && paymentMethod !== 'offline';
  return (
    <div className={styles.wrapper}>
      {orderPolicy && (
        <PolicyView
          t={t}
          className={styles.policies}
          hasCheckbox={hasCheckbox}
          checkedByDefault={!isButtonDisabled}
          termsAndConditions={orderPolicy.termsAndConditions}
          privacyPolicy={orderPolicy.privacyPolicy}
          onChange={handlePolicyChange}
          openModal={openModal}
          showError={showCheckboxError}
        />
      )}
      {shouldShowContactlessOption && (
        <label className={styles.contactless}>
          <ToggleSwitch
            data-hook={dataHooks.checkoutReviewContactlessToggle}
            checked={isContactlessDeliveryChecked}
            onChange={() => {
              setContactlessDelivery({ isContactless: !isContactlessDeliveryChecked });
            }}
          />
          <Text typography="p3-s" className={styles.contactlessLabel}>
            {t('checkout_main_orderreview_outsidedoor_checkbox')}
          </Text>
        </label>
      )}
      <Button
        upgrade
        onClick={() => {
          if (isButtonDisabled) {
            setShowCheckboxError(true);
          } else {
            submitOrder();
          }
        }}
        data-hook={dataHooks.checkoutReviewSubmitOrder}
        fullWidth
        priority={PRIORITY.primary}
        className={styles.continueButton}
        loading={isLoading}
        disabled={isLoading}
      >
        <Text typography="p2-m-colorless">{t('checkout_main_orderreview_submit_button')}</Text>
      </Button>
    </div>
  );
};
CheckoutSubmit.displayName = 'CheckoutSubmit';

export default CheckoutSubmit;
