import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { translate, useBi, useEnvironment } from 'yoshi-flow-editor-runtime';
import DrawerLayout from '../DrawerLayout';
import Cart from '../Cart';
import { PRIORITY } from 'wix-ui-tpa/Button';
import dataHooks from '../../data-hooks';
import styles from './CartModal.scss';
import CartTitle from '../CartTitle';
import { Modals, RouteUrls } from '../../../../core/constants';
import { scroller } from 'react-scroll';
import { DRAWER_LAYOUT_CONTENT_CONTAINER } from '../DrawerLayout/DrawerLayout';
import { TranslationFunction } from 'i18next';
import Button from '../Button';
import NotificationBar from '../NotificationBar';
import Text from '../../core-components/Text';
import { BusinessNotifications, DisplayableOrderItem, DispatchType, Address } from '@wix/restaurants-client-logic';
import { OpenModalPayload } from '../../../../state/session/session.actions.types';
import { isOrderItemListValid } from './CartModal.helper';
import ErrorBanner from '../ErrorBanner';

export interface CartModalProps {
  itemCount: number;
  onRequestClose: () => void;
  displayableOrderItems: DisplayableOrderItem[];
  t: TranslationFunction;
  businessNotifications: BusinessNotifications[];
  isMobile: boolean;
  mobileCheckoutMode?: boolean;
  openModal: (payload: OpenModalPayload) => void;
  isMinimumPriceMet?: boolean;
  displayableAmountLeft?: string;
  minOrderPrice: number;
  dispatchType: DispatchType;
  totalOrderPrice: number;
  address?: Address;
  clickGoToCheckout: () => void;
}

const shouldShowNotification = (notifications: BusinessNotifications[], isPreview: boolean) => {
  const OlOunavailable = notifications.includes(BusinessNotifications.OlOunavailable);
  const NotPremium = notifications.includes(BusinessNotifications.NotPremium);
  const NoPaymentMethods = notifications.includes(BusinessNotifications.NoPaymentMethods);
  const NoPhysicalLocation = notifications.includes(BusinessNotifications.NoPhysicalLocation);

  return (OlOunavailable || NotPremium || NoPaymentMethods || NoPhysicalLocation) && !isPreview;
};

const isCheckoutBlockedByBusinessNotification = (notifications: BusinessNotifications[], isPreview: boolean) => {
  const RestaurantClosed = notifications.includes(BusinessNotifications.RestaurantClosed);
  const OnlyFutureOrders = notifications.includes(BusinessNotifications.OnlyFutureOrders);

  return (shouldShowNotification(notifications, isPreview) || RestaurantClosed || OnlyFutureOrders) && !isPreview;
};

function useEnvironmentSafe() {
  try {
    return useEnvironment();
  } catch (e) {
    return { isPreview: false };
  }
}

const CartModal: React.FC<CartModalProps> = ({
  itemCount,
  onRequestClose,
  displayableOrderItems,
  t,
  businessNotifications,
  isMobile,
  mobileCheckoutMode,
  openModal,
  isMinimumPriceMet = true,
  displayableAmountLeft,
  minOrderPrice,
  dispatchType,
  totalOrderPrice,
  address,
  clickGoToCheckout,
}) => {
  const biLogger = useBi();
  const history = useHistory();

  const { isValid, firstInvalidItem: errorItemIndex } = isOrderItemListValid(displayableOrderItems);
  const hasError = !isValid;

  const { isPreview } = useEnvironmentSafe();

  const shouldDisableGoToCheckout =
    !itemCount ||
    hasError ||
    isCheckoutBlockedByBusinessNotification(businessNotifications, isPreview) ||
    !isMinimumPriceMet;

  const footer = !mobileCheckoutMode ? (
    <div className={styles.footer}>
      <NotificationBar
        shouldShowNotification={shouldShowNotification.bind(undefined, businessNotifications, isPreview)}
        hasErrorState
        className={styles.notificationBarCart}
        ShouldBeRenderedByDispatchTime
      />
      {!isMinimumPriceMet && (
        <ErrorBanner
          value={t('checkout_main_order_minprice_errormessage', { amount: displayableAmountLeft })}
          data-hook={dataHooks.cartMinOrderPriceErrorBanner}
        />
      )}
      <Button
        upgrade
        fullWidth
        disabled={shouldDisableGoToCheckout}
        priority={PRIORITY.primary}
        data-hook={dataHooks.cartGoToCheckoutButton}
        onClick={() => {
          if (hasError) {
            scroller.scrollTo(dataHooks.cartDishItem(errorItemIndex), {
              smooth: 'easeInOutCubic',
              duration: 200,
              offset: -50,
              containerId: DRAWER_LAYOUT_CONTENT_CONTAINER,
            });
            return;
          }
          if (isPreview && businessNotifications.includes(BusinessNotifications.NoPaymentMethods)) {
            openModal({ modal: Modals.CONNECT_PAYMENT_MODAL });
            biLogger.previewOnboardingModalLoad({ type: 'Payments' });
            return;
          } else if (isPreview && businessNotifications.includes(BusinessNotifications.NotPremium)) {
            openModal({ modal: Modals.UPGRADE_TO_PREMIUM_MODAL });
            biLogger.previewOnboardingModalLoad({ type: 'Premium' });
            return;
          }
          if (!isMinimumPriceMet) {
            biLogger.liveSiteMinimumOrderError({
              price: totalOrderPrice,
              minimumOrder: minOrderPrice,
              pageName: 'Checkout',
              dispatchType,
            });
          }
          onRequestClose();
          clickGoToCheckout();
          biLogger.goToCheckout({ totalItemsCount: itemCount });
          history.push(RouteUrls.CHECKOUT_FLOW);
        }}
      >
        <Text typography="p2-m-colorless">{t('cart_cta_checkout')}</Text>
      </Button>
    </div>
  ) : null;

  const cartProps = {
    disabled: shouldDisableGoToCheckout,
    hideTotal: true,
    hideMinOrderPrice: mobileCheckoutMode || (!address && dispatchType === 'delivery'),
    isCartImmutable: mobileCheckoutMode,
    selectedAddressOption: address,
  };

  return (
    <DrawerLayout
      header={<CartTitle typograghy="header-xs" />}
      footer={footer}
      onCloseClick={() => {
        biLogger.cartDiscard({});
        onRequestClose();
      }}
      hook={dataHooks.cartModal}
      isMobile={isMobile}
    >
      <Cart {...cartProps} />
    </DrawerLayout>
  );
};

export default translate()(CartModal);
