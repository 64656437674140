import {
  Address,
  DispatchType,
  EMPTY_ADDRESS,
  getMinOrderPriceDetails,
  Restaurant,
} from '@wix/restaurants-client-logic';
import { cartSummarySelector } from '../../state/selectors/cartSummarySelector';
import { getVirtualDispatchTypeFromDispatch } from '../logic/dispatchLogic';
import { PartialLocation } from '../oloApi';
import { useAppSelector } from './redux';

interface MinOrderPriceOverrides {
  location?: PartialLocation;
  address?: Address;
  dispatchType?: DispatchType;
}

/**
 * By default dispatch type and address are taken from the checkout.dispatch state
 */
export default function useMinOrderPriceDetails({
  location,
  address,
  dispatchType: overrideDispatchType,
}: MinOrderPriceOverrides | undefined = {}) {
  const { totalOrderPrice, restaurant, selectedAddressOption, dispatchType } = useAppSelector((state) => {
    const { priceComponents } = cartSummarySelector(state);
    return {
      totalOrderPrice: priceComponents.total,
      restaurant: (location as Restaurant) ?? state.session.restaurant,
      selectedAddressOption:
        state.checkout.dispatch.type === 'delivery' ? address ?? state.checkout.dispatch.address : EMPTY_ADDRESS,
      dispatchType: overrideDispatchType ?? getVirtualDispatchTypeFromDispatch(state.checkout.dispatch),
    };
  });

  const details = getMinOrderPriceDetails({
    totalOrderPrice,
    restaurant,
    dispatchType,
    selectedAddressOption,
  });

  return {
    ...details,
    isMinOrderPriceMet: details.amountLeft === 0,
  };
}
