import { AppState } from '../createStore';
import { createSelector } from 'reselect';
import { PartialLocation } from '../../core/oloApi';
import { BusinessNotifications, getAllBusinessNotifications, Restaurant } from '@wix/restaurants-client-logic';

/**
 * @returns only open locations, that is OLO is available and the location is not closed
 */
export const selectOpenLocations = createSelector(
  [
    (state: AppState) => state.session.locations,
    (state: AppState) => state.session.restaurant.products,
    (state: AppState) => state.session.paymentMethods.length > 0,
  ],
  (locations: PartialLocation[], products: Restaurant['products'], hasAnyPaymentMethods: boolean) => {
    return locations
      .map((location) => {
        const notifications = getAllBusinessNotifications(
          {
            ...location,
            products,
          } as Restaurant,
          hasAnyPaymentMethods,
        );
        return {
          location,
          notification: notifications.length > 0 ? notifications[0].notification : undefined,
        };
      })
      .filter(({ notification }) => {
        return (
          notification !== BusinessNotifications.RestaurantClosed &&
          notification !== BusinessNotifications.OlOunavailable
        );
      })
      .map(({ location }) => location);
  },
);

/**
 * @returns only open locations, that is OLO is available and the location is not closed
 */
export const selectLocationsWithNotification = createSelector(
  [
    (state: AppState) => state.session.locations,
    (state: AppState) => state.session.restaurant.products,
    (state: AppState) => state.session.paymentMethods.length > 0,
  ],
  (locations: PartialLocation[], products: Restaurant['products'], hasAnyPaymentMethods: boolean) => {
    return locations.map((location) => {
      const notifications = getAllBusinessNotifications(
        {
          ...location,
          products,
        } as Restaurant,
        hasAnyPaymentMethods,
      );
      return {
        location,
        notification: notifications.length > 0 ? notifications[0].notification : undefined,
      };
    });
  },
);
export type LocationsWithNotification = ReturnType<typeof selectLocationsWithNotification>;
