import React from 'react';
import Spinner from '../Spinner';
import {
  Address,
  extractLocalizedString,
  getLocationsWithTakeout,
  validateAddressForLocations,
  isAddress,
  VirtualDispatchType,
  getLocationsWithDineIn,
} from '@wix/restaurants-client-logic';
import Text from '../../core-components/Text';
import Dropdown from '../Dropdown';
import dataHooks from '../../data-hooks';
import styles from './ChooseLocationDropdown.scss';
import { PartialLocation } from '../../../../core/oloApi';
import { useTranslation } from 'yoshi-flow-editor-runtime';
import _ from 'lodash';

interface Props {
  locations: PartialLocation[];
  onChange(id?: string): void;
  selectedAddressOption?: Address;
  labelText: string;
  isLoading?: boolean;
  filters?: ((location: PartialLocation) => boolean) | VirtualDispatchType;
  className?: string;
  location?: string;
  compId: string;
}

const ChooseLocationDropdown: React.FC<Props> = ({
  locations,
  onChange,
  labelText,
  selectedAddressOption,
  isLoading,
  filters = () => true,
  className,
  location,
  compId,
}) => {
  const { t } = useTranslation();
  let filteredLocations: PartialLocation[] = [];

  if (typeof filters === 'function') {
    filteredLocations = locations.filter(filters);
  } else {
    if (filters === 'takeout') {
      filteredLocations = getLocationsWithTakeout(locations);
    } else if (filters === 'delivery' && isAddress(selectedAddressOption)) {
      const { hasLocations, locations: validatedLocations } = validateAddressForLocations(
        locations,
        selectedAddressOption,
      );
      filteredLocations = hasLocations && validatedLocations ? validatedLocations : [];
    }
    if (filters === 'dine-in') {
      filteredLocations = getLocationsWithDineIn(locations);
    }
  }

  if (isLoading) {
    return (
      <div className={styles.spinner} data-hook={dataHooks.chooseLocation}>
        <Spinner diameter={18} isCentered />
      </div>
    );
  }

  if (filteredLocations.length === 0 || (filters === 'delivery' && filteredLocations.length === 1)) {
    return null;
  }

  if (filteredLocations.length === 1) {
    return (
      <div className={className} data-hook={dataHooks.chooseLocation}>
        <Text typography="p2-s" className={styles.label}>
          {labelText}
        </Text>
        <Text typography="p2-m" data-hook={dataHooks.chooseLocationAddress}>
          {filteredLocations[0].address.formatted}
        </Text>
      </div>
    );
  }

  const placeholder =
    filters === 'takeout' || filters === 'dine-in'
      ? t('checkout_main_deliverymethod_modal.location.placeholdertext')
      : '';
  return (
    <div className={className} data-hook={dataHooks.chooseLocation}>
      <Text tagName="label" typography="p2-s" className={styles.label}>
        {labelText}
      </Text>
      <Dropdown
        upgrade
        optionsContainerZIndex={1020}
        appendTo="window"
        optionsContainerClass={compId}
        placeholder={placeholder}
        data-hook={dataHooks.chooseLocationDropdown}
        options={filteredLocations.map((loc) => ({
          id: loc.currentLocationId,
          value: extractLocalizedString(loc.locationName ?? {}, loc.locale),
          subtitle: loc.address.formatted,
          isSelectable: true,
        }))}
        initialSelectedId={location}
        onChange={({ id }) => {
          onChange(id);
        }}
        mobileNativeSelect
      />
    </div>
  );
};

export default ChooseLocationDropdown;
