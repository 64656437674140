import { connect } from 'react-redux';
import DispatchTimeSelector from './DispatchTimeSelector';
import { AppState } from '../../../../state/createStore';

function mapStateToProps(state: AppState) {
  return {
    isMl: state.session.isMultiLocation,
    address: state.addressForm.selectedAddressOption
  };
}

export default connect(mapStateToProps)(DispatchTimeSelector);
